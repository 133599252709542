import { useCallback, createContext, useContext } from 'react'
import { useForceUpdate } from 'hooks'
import { useCookieCoupon } from 'modules/coupon'
import { usePrices } from 'modules/subscription'
import { useUser } from 'modules/user'


type SubscribeOfferContextValue = {
  couponCode: string
  cookieCouponCode: string
  price: number
  priceWithDiscount: number
  extraShippingPrice: number
  formattedPrice: string
  formattedPriceWithDiscount: string
  formattedExtraShippingPrice: string
  offerType: SubscriptionModule.OfferType
  discountText: string
  discountPercent: string
  discountAmount: string
  eventDiscountText: SubscriptionModule.EventDiscountText
  isFetching: boolean
  handleSubscribeClick: () => void
  replaceCoupon: (code: string) => void
  forceUpdate: () => void
}

const SubscribeOfferContext = createContext<SubscribeOfferContextValue>(null)

export const useSubscribeOffer = () => useContext(SubscribeOfferContext)

export const useSubscribeOfferProvider = (defaultCouponCode?: string): SubscribeOfferContextValue => {
  const [ , forceUpdate ] = useForceUpdate()
  const { couponCode, cookieCouponCode, setCookieCoupon } = useCookieCoupon(defaultCouponCode)

  const { country } = useUser()

  const {
    price,
    priceWithDiscount,
    extraShippingPrice,
    formattedPrice,
    formattedPriceWithDiscount,
    formattedExtraShippingPrice,
    eventDiscountText,
    discountPercent,
    discountAmount,
    isFetching,
    offerType,
  } = usePrices({ couponCode, country })

  const discountText = discountPercent || discountAmount

  const handleSubscribeClick = useCallback(() => {
    setCookieCoupon(couponCode)
  }, [ setCookieCoupon, couponCode ])

  // use if you want to replace coupon globally
  const replaceCoupon = useCallback((code: string) => {
    setCookieCoupon(code)
    forceUpdate()
  }, [ setCookieCoupon, forceUpdate ])

  return {
    couponCode,
    cookieCouponCode, // code which is already saved or applied by user
    price,
    priceWithDiscount,
    extraShippingPrice,
    formattedPrice,
    formattedPriceWithDiscount,
    formattedExtraShippingPrice,
    offerType,
    discountText,
    discountAmount,
    discountPercent,
    eventDiscountText,
    isFetching,
    handleSubscribeClick,
    replaceCoupon,
    forceUpdate,
  }
}

export const SubscribeOfferProvider: React.CFC<{ defaultCouponCode?: string }> = ({ children, defaultCouponCode }) => {
  const value = useSubscribeOfferProvider(defaultCouponCode)

  return (
    <SubscribeOfferContext.Provider value={value}>
      {children}
    </SubscribeOfferContext.Provider>
  )
}
