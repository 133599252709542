import { useMemo } from 'react'
import { useQuery } from 'apollo-client'
import { useIntl } from 'intl'
import { getPlanDifferencePercent } from 'helpers/getters'
import { useCountryList } from 'modules/user'

import plansQuery, { type PlansVariables } from './graph/plans.graphql'


type UsePricesProps = PlansVariables['input'] & {
  gender?: PlansVariables['gender'] // for coupon with free product
  country?: Intl.CountryCode
  skip?: boolean
  ssr?: boolean
}

export const usePlanPricesList = ({ skip, ssr, couponCode, gender = 'FEMALE', country, ...input }: UsePricesProps = {}) => {
  const {
    data: plansData,
    isFetching: isFetchingPlan,
    error,
  } = useQuery(plansQuery, {
    variables: {
      gender,
      input: {
        billingPeriod: 1,
        shippingPeriod: 1,
        productsPerPeriod: 1,
        couponCode: couponCode || '', // ATTN empty string is required to remove default coupon
        ...input,
      },
    },
    skip,
    ssr,
    context: {
      ssrCache: true,
    },
  })

  const intl = useIntl()

  const finalCountry = country || 'US'
  const isUS = finalCountry === 'US'
  const { countryList, isFetching: isFetchingCountry } = useCountryList({ withRegions: false, skip: isUS })

  const isFetching = isFetchingPlan || isFetchingCountry

  const data = useMemo(() => {
    const plans = plansData?.currentUser?.data?.plansInquiry

    if (!plans) {
      return null
    }

    const countryInfo = countryList ? countryList.find(({ alpha2 }) => alpha2 === finalCountry) : null
    const { shippingPrice = 0 } = countryInfo || {}

    return plans.map((plan) => {
      const price = plan.price
      const priceWithDiscount = plan.discount?.defaultDiscountPrice

      const formattedPrice = price ? intl.formatPrice(price) : null
      const formattedPriceWithDiscount = priceWithDiscount !== undefined ? intl.formatPrice(priceWithDiscount) : null

      let { code, extraShippingPrice = 0, discountAmount, discountPercentage, freeProduct } = plan.discount?.defaultCoupon || {}

      extraShippingPrice = extraShippingPrice + shippingPrice

      const isFreeTrial = priceWithDiscount === 0 && extraShippingPrice > 0
      // TODO detect "2nd month offer" on backend in API - added on 12.04.2022 by sonatskiy
      const isSecondMothFreeOffer = code === '2ND_MTH_FREE_8FE998'
      const isFreeProductOffer = Boolean(freeProduct?.id)
      const isFreeCaseOffer = freeProduct?.typeGroup === 'PerfumeCase'


      const formattedExtraShippingPrice = extraShippingPrice ? intl.formatPrice(extraShippingPrice) : null
      const formattedDiscountAmount = discountAmount ? intl.formatPrice(discountAmount) : null
      const discountPercent = discountPercentage ? `${discountPercentage}%` : null

      let eventDiscountText: SubscriptionModule.EventDiscountText
      let offerType: SubscriptionModule.OfferType

      if (isFreeTrial) {
        eventDiscountText = !isUS ? `${formattedExtraShippingPrice} delivery discount` : 'Free trial'
        offerType = 'freeTrial'
      }
      else if (isSecondMothFreeOffer) {
        eventDiscountText = 'Free 2nd month product'
        offerType = 'free2ndMonth'
      }
      else if (isFreeProductOffer) {
        eventDiscountText = isFreeCaseOffer ? 'Free case' : 'Free product'
        offerType = 'freeProduct'
      }
      else {
        eventDiscountText = discountPercent as `${number}%` || formattedDiscountAmount as `$${number}` || 'No offer'
        offerType = discountAmount || discountPercent ? 'discount' : null
      }

      // TODO refactor it to something more pretty :) - added on 12.04.2022 by sonatskiy
      return {
        plan: {
          ...plan,
          percentageDiscount: getPlanDifferencePercent(plans[0].productPrice, plan.productPrice),
        },
        price,
        priceWithDiscount,
        extraShippingPrice,
        formattedPrice,
        formattedPriceWithDiscount,
        formattedExtraShippingPrice,
        eventDiscountText,
        discountAmount: formattedDiscountAmount,
        discountPercent,
        freeProduct,
        offerType,
        isFreeTrial,
        isSecondMothFreeOffer,
        isFreeProductOffer,
        isFreeCaseOffer,
      }
    })
  }, [ intl, plansData, countryList, finalCountry ])

  return {
    data,
    isFetching,
    error,
  }
}

export const usePrices = (props?: UsePricesProps) => {
  const { data, isFetching, error } = usePlanPricesList(props)

  return {
    ...data?.[0],
    isFetching,
    error,
  }
}
